<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">
        <v-row>
            <v-col>
                <v-container class="my-5 px-3">
                    <v-row class="px-3">
                        <v-col cols="0" md="2">
                            <admin-etc-menu></admin-etc-menu>
                        </v-col>
                        <v-col cols="12" md="9">

                            <v-row>
                                <v-col cols="12" class="sub-title-3 mt-2">
                                    {{title}}
                                </v-col>
                                <!--<v-col cols="12">
                                    <input type="radio" id="0" value="0" v-model="comnOrder"
                                           style="width: 20px; height: 12px;" @change="changRadio($event)">
                                    <label for="0">큰화면(960px 이상)</label>
                                    <input type="radio" id="1" value="1" v-model="comnOrder"
                                           style="width: 20px; height: 12px; margin-left:20px;"
                                           @change="changRadio($event)">
                                    <label for="1">작은화면(960px 이하)</label>
                                </v-col>-->
                                <v-col cols="12" class="sub-title-3 mt-2 pb-0">
                                    <span style="font-weight: bold; font-size:0.9rem;">
                                        사진등록 (추가등록시 파일을 선택해주세요.)
                                    </span>

                                    <v-btn
                                            color="primary"
                                            class="mx-auto white--text center-block align-center float-right"
                                            small
                                            @click="btnClick"
                                    >
                                        저장
                                    </v-btn>
                                </v-col>
                                <!--<v-col cols="12">
                                    <input type="file" name="files" accept="image/*" @change="uploadImage"/>
                                    <v-row class="my-3">
                                        <v-col
                                                cols="6" md="4"
                                                v-for="(item,i) in imgList"
                                                :key="i"
                                        >
                                            <v-card>
                                                <v-img
                                                        :src="item.roomImgRoute"
                                                        :lazy-src="item.roomImgRoute"
                                                        aspect-ratio="1.5"
                                                        class="grey lighten-2"
                                                ></v-img>
                                                <v-card-title class="text-h6">

                                                </v-card-title>
                                                <v-card-subtitle>
                                                    <div>순서 :
                                                        <input id="roomImgOrder" v-model="item.roomImgOrder"
                                                               style="border: 1px solid silver;width: 25%;padding:0 3px;">
                                                        <v-btn
                                                                color="error"
                                                                class="mx-0 white&#45;&#45;text center-block float-right"
                                                                x-small
                                                                @click="delImg(item)"
                                                        >
                                                            삭제
                                                        </v-btn>
                                                    </div>
                                                </v-card-subtitle>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
-->
                                <v-col cols="12">
                                    <v-row class="my-3">
                                        <v-col
                                                cols="12" md="12"
                                                v-for="(item,i) in imgList"
                                                :key="i"
                                                class="img-card"
                                                style="text-align: center;"
                                        >
                                            <img
                                                    :src="item.roomImgRoute"
                                                    class="grey lighten-2"
                                            ></img>
                                            <div class="img-card-title">
                                                <input type="file" name="files" accept="image/*" @change="uploadImage($event, item)"/>
                                            </div>


                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12">


                                </v-col>
                                <!--<v-col class="mt-6">
                                    <v-btn
                                            color="primary"
                                            class="mx-auto white&#45;&#45;text center-block align-center"
                                            small
                                            @click="btnClick"
                                    >
                                        저장
                                    </v-btn>
                                </v-col>-->

                            </v-row>

                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import AdminEtcMenu from '../../components/AdminEtcMenu'

    export default {
        name: 'AdminImgInfo',
        components: {
            AdminEtcMenu
        },
        data: () => ({
            title: "",
            imgGubun: "",
            imgList:"",
            comnOrder: "0",
            comnInfo: {
                imgGubun: "",
                comnOrder: "",
                comnNm: "",
                comnCont: "",
            },

            quill: null,

        }),
        created: function () {
            // 맨위로
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));

            if (this.$isMobile()) {
                window.scrollTo({top: 400, left: 0, behavior: 'smooth'});
            } else {
                window.scrollTo(0, 0);
            }

            let _this = this;

            const sch = window.location.search;
            const urlParams = new URLSearchParams(sch);

            let imgGubun = urlParams.get('imgGubun');
            //let comnOrder = urlParams.get('comnOrder');

            if (imgGubun === null || imgGubun === "") {
                this.imgGubun = 'BAT';
            } else {
                this.imgGubun = imgGubun;
            }

            this.setTitle();
            //this.imgGubun = imgGubun;
            this.getData();


        },
        mounted() {
            this.$nextTick(() => {

                let _this = this;

            })

        },

        methods: {
            setTitle(){
                switch ( this.imgGubun) {
                    case "BAT":{
                        this.title = "배치도";
                        break;
                    }
                    case "WTC":{
                        this.title = "오시는길";
                        break;
                    }
                    default: {

                        this.title = "배치도";

                        break;
                    }
                }

            },
            changRadio(event) {
                this.getData();
            },
            getData() {

                let formdata = {
                    roomId: this.imgGubun,
                    //roomImgOrder : this.comnOrder,
                };

                return this.$store.dispatch("admin/getRoomImg", formdata)
                    .then((resp) => {

                        if (resp.message === undefined) {
                            //alert("잘못된 요청입니다.");
                            //window.history.back();
                            //return;
                        } else {
                            this.imgList = resp.message;
                        }

                    })
                    .catch((err) => {
                    })

            },

            menuClick(route) {
                location.href = route;
                //this.$router.push({path: route});
            },
            btnClick() {

                let formdata = {
                    roomImgId: this.roomImgId,
                    roomId: this.imgGubun,
                    roomImg: this.imgList,
                    lstModPrs: this.$store.state.admin.loginInfo.usrId
                };

                return this.$store.dispatch("admin/updateRoomImg", formdata)
                    .then((resp) => {
                        setTimeout(() => {

                            alert("저장완료");
                            location.reload()


                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            uploadImage(e) {
                let url = '/file/upload.do';

                let formData = new FormData();
                formData.append('file', e.target.files[0]);

                let header = {
                    headers: {'Content-Type': 'multipart/form-data'}
                };

                return axios.post(url, formData, header).then(resp => {

                    if (resp.data) {

                        this.roomImgRoute_tmp = resp.data;
                        let roomImgInfo = {
                            roomId: this.imgGubun,
                            roomImgOrder: "0",
                            roomImgRoute: this.roomImgRoute_tmp,
                            roomImgId: "",
                            lstModPrs: this.$store.state.admin.loginInfo.usrId,
                            isAdd: true,

                        };

                        this.imgList = [];

                        this.imgList.push(roomImgInfo);

                        return resp.data
                    }

                    throw new Error('Server or network error');
                });
            },
            addImg() {
                if (this.roomImgRoute_tmp.length > 0) {

                    let roomImgInfo = {
                        roomId: this.roomId,
                        roomImgOrder: "0",
                        roomImgRoute: this.roomImgRoute_tmp,
                        roomImgId: "",
                        lstModPrs: this.$store.state.admin.loginInfo.usrId,
                        isAdd: true,

                    };

                    this.imgList.push(roomImgInfo);

                } else {
                    alert("사진을 업로드 해주세요.");
                }

            },
            delImg(item) {

                let _this = this;

                if (!confirm("사진을 삭제 하시겠습니까?")) {
                    // 취소(아니오) 버튼 클릭 시 이벤트
                } else {
                    // 확인(예) 버튼 클릭 시 이벤트
                    _this.imgList.forEach(function (item1, index, arr2) {
                        if (item1.roomImgRoute !== undefined && item.roomImgRoute !== undefined) {
                            if (item1.roomImgRoute === item.roomImgRoute) {
                                _this.imgList.splice(index, 1);
                            }
                        }
                    });
                }

            }

        },
        computed: {
            //usrInfo() { return this.$store.state.reservation.usrInfo }
        },

    }
</script>

<style>


    /*.ql-editor {
        height: 550px;
    }*/
</style>
